@tailwind base;
@tailwind components;
@tailwind utilities;

header.sticky {
    position: sticky;
    top: 0;
    z-index: 100;
}

img.image {
    height: 400px;
    width: 300px;
}

img.projectPic {
    height: 240px;
    width: 400px;
}

img.popUpPic {
    align-self: center;
    height: 300px;
    width: 500px;
}

html {
    scroll-behavior: smooth;
    background-color: rgb(31, 41, 55);
}
.modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    text-align: center;
    padding: 5px;
}
.modal > .content {
    width: 100%;
    padding: 10px 5px;
}
.modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #000000;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}
  
.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

#hover:hover > svg{
    stroke: rgb(34, 197, 94)
}